import { Container, Desc, DescContainer, H1, H3, MailId } from "./styled"

const Impressum = () => (
  <Container>
    <H1>IMPRESSUM</H1>
    <DescContainer>
      <Desc>
        Responsible for the content according to § 55 Abs. 2 RStV: Rajyashree
        Ramesh.
      </Desc>
      <Desc>
        Mail: {' '}
        <MailId href={"mailto:fascianatya@gmail.com"}>
          fascianatya@gmail.com
        </MailId>
      </Desc>

      <Desc> Fotos: Archives of Rajyashree Ramesh </Desc>

      <H3> Disclaimer </H3>
      <Desc>Liability for contents </Desc>
      <Desc>
        As a service provider, we are responsible for our own content on these
        pages according to § 7 para. 1 TMG (German Telemedia Act) and general
        laws. According to §§ 8 to 10 TMG we are not obliged to monitor
        transmitted or stored information or to investigate circumstances that
        indicate illegal activity. Obligations to remove or block the use of
        information according to general laws remain unaffected. However,
        liability in this respect is only possible from the time of knowledge of
        a concrete infringement. If we become aware of any such violations, we
        will remove the content in question immediately.{" "}
      </Desc>
      <Desc>Liability for links </Desc>
      <Desc>
        Our offer contains links to external websites of third parties, on whose
        contents we have no influence. Therefore we cannot assume any liability
        for these external contents. The respective provider or operator of the
        sites is always responsible for the contents of the linked sites. The
        linked pages were checked for possible legal violations at the time of
        linking. Illegal contents were not identified at the time of linking.
        However, a permanent control of the contents of the linked pages is not
        reasonable without concrete evidence of a violation of the law. If we
        become aware of any infringements, we will remove such links
        immediately.{" "}
      </Desc>
      <Desc>Copyright </Desc>
      <Desc>
        The contents and works on these pages created by the site operators are
        subject to German copyright law. The duplication, editing, distribution
        and any kind of utilization outside the limits of the copyright law
        require the written consent of the respective author or creator.
        Downloads and copies of these pages are only permitted for private,
        non-commercial use. Insofar as the content on this site was not created
        by the operator, the copyrights of third parties are observed. In
        particular, third-party content is identified as such. Should you
        nevertheless become aware of copyright infringement, please inform us
        accordingly. If we become aware of any infringements, we will remove
        such contents immediately.{" "}
      </Desc>
    </DescContainer>
  </Container>
)

export default Impressum
