import * as React from "react"
import Impressum from "../components/impressum"
import Layout from "../components/layout"
import Seo from "../components/seo"

const logo = require("../images/logo.svg").default

const MovementStudiesPage = () => (
  <Layout
    backgroundColor="white"
    logo={logo}
    menuBGColor="black"
    hamburgerColor="black"
    footerColor="black"
  >
    <Seo title="Rajyashree Ramesh" />
    <Impressum />
  </Layout>
)

export default MovementStudiesPage
