import styled from "@emotion/styled"

export const H1 = styled("h1")`
  font-family: Marcellus;
  font-style: normal;
  font-weight: normal;
  font-size: 34px;
  line-height: 24px;
  color: #000000;
  width: 100%;
  text-align: center;
  margin-bottom: 2%;
`

export const H3 = styled("h3")`
  font-family: Marcellus;
  font-style: normal;
  font-weight: normal;
  font-size: 34px;
  line-height: 24px;
  padding: 30px 0;
  color: #000000;
  width: 100%;
  margin-bottom: 2%;
  margin-top: 2%;
`

export const Paragraph = styled("p")`
  width: 100%;
  height: 100%;
  font-family: Marcellus;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  text-align: center;
  color: #000000;
  margin-top: 2%;
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  padding: 20px 100px;

  @media (max-width: 768px) {
    padding: 20px;
    text-align: center;
  }
`

export const Desc = styled.div`
  padding-bottom: 20px;
  font-family: Marcellus;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 1.5;
  @media (max-width: 860px) {
    font-size: 18px;
  }
`

export const MailId = styled.a`
  width: 300px;
  height: 50px;
  margin-top: 30px;
  font-family: Marcellus;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 1.2;
  color: black;

  @media (max-width: 860px) {
    font-size: 18px;
  }
`

export const DescContainer = styled.div`
  padding: 50px 0;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  @media (max-width: 860px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
`
